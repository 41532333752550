import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
`;

const Heading = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
`;

const VideoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
`;

const VideoCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
`;

const Thumbnail = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const VideoTitle = styled.h2`
  font-size: 1.2rem;
  margin-top: 10px;
`;

const videos = [
  {
    title: 'Web3.py Basics',
    thumbnail: 'https://img.youtube.com/vi/-4yAIRualxM/0.jpg',
    url: 'https://www.youtube.com/watch?v=-4yAIRualxM&list=PLf6bwte-_HkKx_Z8E2LVCwjINN29KcUqS',
  },
  {
    title: 'Web3.js Videos',
    thumbnail: 'https://img.youtube.com/vi/BXaZl6d0Rps/0.jpg',
    url: 'https://www.youtube.com/watch?v=BXaZl6d0Rps&list=PLf6bwte-_HkIJ2aj4PdUpXHWmJJBoYuvz',
  },
  {
    title: 'Ethers.js Basics',
    thumbnail: 'https://img.youtube.com/vi/Ks7IcQBJk14/0.jpg',
    url: 'https://www.youtube.com/watch?v=Ks7IcQBJk14&list=PLf6bwte-_HkLD-ImA9lAHaKCpzpgXv57w',
  },
  {
    title: 'Solidity',
    thumbnail: 'https://img.youtube.com/vi/SbWwfKo3scc/0.jpg',
    url: 'https://www.youtube.com/watch?v=SbWwfKo3scc&list=PLf6bwte-_HkL-jieCAXvhlmUHiYAFtKuD',
  },
  {
    title: 'Fundamental Concepts',
    thumbnail: 'https://img.youtube.com/vi/6Nds1pT53WU/0.jpg',
    url: 'https://www.youtube.com/watch?v=6Nds1pT53WU&list=PLf6bwte-_HkIfSQUshcxkx8YqxrN5zS5j',
  }
];

const YouTube = () => (
  <Container>
    <Heading>My YouTube Channel</Heading>
    <VideoGrid>
      {videos.map((video, index) => (
        <VideoCard key={index} onClick={() => window.open(video.url, '_blank')}>
          <Thumbnail src={video.thumbnail} alt={video.title} />
          <VideoTitle>{video.title}</VideoTitle>
        </VideoCard>
      ))}
    </VideoGrid>
  </Container>
);

export default YouTube;
