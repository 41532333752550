import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
`;

const Heading = styled.h1`
  font-size: 2.1rem;
  margin-bottom: 20px;
  text-align: center;
`;

const ReviewList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ReviewCard = styled.div`
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const ReviewerName = styled.h2`
  font-size: 1.2rem;
  margin-bottom: 10px;
`;

const Platform = styled.p`
  font-size: 1rem;
  color: #555;
  margin-bottom: 10px;
`;

const ReviewText = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 10px;
`;

const ReviewDate = styled.p`
  font-size: 1rem;
  color: #777;
  text-align: right;
`;

const Stars = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const Star = styled.span`
  font-size: 1.5rem;
  color: ${props => props.filled ? '#FFD700' : '#ccc'};
  margin-right: 5px;
`;

const reviews = [
  {
    name: 'cgoode3 | United States',
    platform: 'Fiverr',
    text: 'Excellent work and very professional. Would recommend to others and use again for programming services.',
    date: '3 years ago',
    rating: 5,
  },
  {
    name: 'Upwork Client',
    platform: 'Upwork',
    text: 'Himmat did a good job. He was very flexible, professional and was able to do the job well, and sometimes even earlier than expected. Would definitely recommend him.',
    date: '3 years ago',
    rating: 5,
  },
  {
    name: 'sirsavesalot | United States',
    platform: 'Fiverr',
    text: 'very patient and understanding seller. will work with again.',
    date: '3 years ago',
    rating: 5,
  },
  {
    name: 'Upwork Client',
    platform: 'Upwork',
    text: 'Himmat completed the task on time and delivered exactly what was required. He is also very understanding and easy to work with.',
    date: '3 years ago',
    rating: 5,
  },
  {
    name: 'bestway2earn | United States',
    platform: 'Fiverr',
    text: 'Awesome work and 100% Great Support, he has great knowledge in blockchain technology.',
    date: '3 years ago',
    rating: 5,
  },
  {
    name: 'priteshkucheria | United States',
    platform: 'Fiverr',
    text: 'Himmat is great. Will definitely work again with him if his bandwidth permits for anything crypto.',
    date: '3 years ago',
    rating: 5,
  },
  {
    name: 'user34193314 | Germany',
    platform: 'Fiverr',
    text: 'everything as described',
    date: '3 years ago',
    rating: 5,
  },
  {
    name: 'anique148 | Japan',
    platform: 'Fiverr',
    text: 'Best developer',
    date: '3 years ago',
    rating: 5,
  },
  {
    name: 'sysco32 | Macedonia [FYROM]',
    platform: 'Fiverr',
    text: 'Delivered very fast. Gave good instructions',
    date: '3 years ago',
    rating: 5,
  },
  {
    name: 'aaparkly | United States',
    platform: 'Fiverr',
    text: 'very good coder, understand requirements and was patient with bugs',
    date: '3 years ago',
    rating: 5,
  },
  {
    name: 'rosucarol | Romania',
    platform: 'Fiverr',
    text: 'Everything was great, he helped me setup and explained how it works!',
    date: '3 years ago',
    rating: 5,
  },
  {
    name: 'tenaciousteo | Singapore',
    platform: 'Fiverr',
    text: 'Well done as promised',
    date: '2 years ago',
    rating: 5,
  },
  {
    name: 'ncikwilliams | Hong Kong',
    platform: 'Fiverr',
    text: 'Seller was very professional and worked on time to deliver the project. I have placed another order with the Seller as the communication with the Seller was very professional. Great GIG. Thank you for all your hard work.',
    date: '1 years ago',
    rating: 5,
  },
  {
    name: 'ncikwilliams | Hong Kong',
    platform: 'Fiverr',
    text: 'Another project was done on time and professionally. Great with communication and understand the tasks 100%. Will order again.',
    date: '1 years ago',
    rating: 5,
  },
  {
    name: 'ncikwilliams | Hong Kong',
    platform: 'Fiverr',
    text: 'This is the third time I have ordered. Very good work from the seller. Understands my project needs completely and worked on any issues that I wanted to change. I will be ordering again very soon.',
    date: '1 years ago',
    rating: 5,
  },
  {
    name: 'sarathchandras | India',
    platform: 'Fiverr',
    text: 'Its really pleasure working with Himmat who did an excellent job. He is very professional and he have completed the task with high quality within the given time . He is also easy to communicate with and always responsive to our needs. I would highly recommend him to anyone looking for quality ouput',
    date: '1 years ago',
    rating: 5,
  },
  {
    name: 'aritrabanerjee | India',
    platform: 'Fiverr',
    text: 'It is such a lovely experience to work with him, he is so prompt in delivery and understanding! Looking forward to build more strategies with his help!',
    date: '1 years ago',
    rating: 5,
  },
  {
    name: 'ncikwilliams | Hong Kong',
    platform: 'Fiverr',
    text: 'I have done over 3 gigs in total and the sellers goes above and beyond. Completely satisfied and will be doing another gog with this seller.',
    date: '10 months ago',
    rating: 5,
  },
  {
    name: 'ncikwilliams | Hong Kong',
    platform: 'Fiverr',
    text: 'Great Gig yet again i highly recommend this seller.',
    date: '10 months ago',
    rating: 5,
  },

];

const renderStars = (rating) => {
  return (
    <Stars>
      {[...Array(5)].map((star, index) => (
        <Star key={index} filled={index < rating}>★</Star>
      ))}
    </Stars>
  );
};

const Reviews = () => (
  <Container>
    <Heading>Client Reviews</Heading>
    <ReviewList>
      {reviews.map((review, index) => (
        <ReviewCard key={index}>
          <ReviewerName>{review.name}</ReviewerName>
          <Platform>{review.platform}</Platform>
          {renderStars(review.rating)}
          <ReviewText>{review.text}</ReviewText>
          <ReviewDate>{review.date}</ReviewDate>
        </ReviewCard>
      ))}
    </ReviewList>
  </Container>
);

export default Reviews;
