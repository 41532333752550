// Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #333;
  padding: 10px 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const Nav = styled.nav`
  background-color: #333;
  color: #fff;
  padding: 5px 5px;
  display: flex;
  justify-content: space-between;
`;

const NavList = styled.ul`
  list-style-type: none;
  padding: 0;
  display: flex;
`;

const NavItem = styled.li`
  margin-right: 20px;
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
`;

const Header = () => (
    <HeaderContainer>
        <Nav>
            <h1>&nbsp;&nbsp;&nbsp;&nbsp;Himmat Patil </h1>
            <NavList>
            <NavItem>
                <NavLink to="/">Home</NavLink>
            </NavItem>
            <NavItem>
                <NavLink to="/about">About Me</NavLink>
            </NavItem>
            <NavItem>
                <NavLink to="/portfolio">Portfolio</NavLink>
            </NavItem>
            <NavItem>
                <NavLink to="/youtube">YouTube</NavLink>
            </NavItem>
            <NavItem>
                <NavLink to="/reviews">Reviews</NavLink>
            </NavItem>
            <NavItem>
                <NavLink to="/contact">Contact</NavLink>
            </NavItem>
            </NavList>
        </Nav>
    </HeaderContainer>
);

export default Header;
