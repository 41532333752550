// Home.js
import React from 'react';
import styled from 'styled-components';
import profileImage from '../assets/images/profile.jpg';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const Content = styled.div`
  flex: 1;
  margin-right: 10px;
  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 5px;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  text-align: center;
`;

const Image = styled.img`
  width: 350px;
  border-radius: 50%;
  @media (max-width: 768px) {
    width: 200px;
  }
`;

const Heading = styled.h1`
  font-size: 2rem;
  margin-bottom: 10px;
`;

const Paragraph = styled.p`
  font-size: 1.1rem;
  line-height: 1.4;
  margin-bottom: 10px;
`;

const SkillsList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const SkillItem = styled.li`
  margin-bottom: 10px;
`;

const CallToAction = styled.div`
  margin-top: 20px;
`;

const Button = styled.a`
  display: inline-block;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 10px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #0056b3;
  }
`;

const Home = () => (
  <Container>
    <Content>
      <Heading>Welcome!</Heading>
      <Paragraph>
        I’m a blockchain developer with over 12+ years of software development experience. I specialize in
        creating secure decentralized applications, optimizing blockchain protocols, and providing insights into the
        latest trends. Explore my portfolio to see my innovative solutions in action.
      </Paragraph>
      <SkillsList>
        <Heading>Key Skills</Heading>
        <SkillItem>
          <strong>Python:</strong> Expert in developing robust backend systems, automating processes, and data
          analysis.
        </SkillItem>
        <SkillItem>
          <strong>Blockchain Technologies:</strong>
          <ul>
            <SkillItem><u>Solidity:</u> Secure, efficient smart contract development for Ethereum and EVM-compatible blockchains.</SkillItem>
            <SkillItem><u>Web3.js & Ethers.js:</u> Seamless blockchain integration into web applications.</SkillItem>
            <SkillItem><u>Web3.py:</u> Python-based blockchain interactions and backend integrations.</SkillItem>
          </ul>
        </SkillItem>
        <SkillItem>
          <strong>Node.js:</strong> High-performance, scalable server-side applications and RESTful APIs.
        </SkillItem>
        <SkillItem>
          <strong>Hadoop Ecosystem:</strong> Large-scale data processing solutions using HDFS, MapReduce, Hive, and Spark.
        </SkillItem>
      </SkillsList>
      <CallToAction>
        <Button href="portfolio">Check out my portfolio</Button>
        <Button href="contact" style={{ marginLeft: '10px' }}>Contact me</Button>
        <Button href="/resume.pdf" download="Himmat_Patil_Blockchain_Developer.pdf" style={{ marginLeft: '10px' }}>Download Resume</Button>
      </CallToAction>
    </Content>
    <ImageContainer>
      <Image src={profileImage} alt="Profile" />
    </ImageContainer>
  </Container>
);

export default Home;
