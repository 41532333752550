// AboutMe.js
import React from 'react';
import styled from 'styled-components';
import fiverr from '../assets/images/fiverr.svg';
import upwork from '../assets/images/upwork.png';
import deloitte from '../assets/images/Deloitte_Logo.png';
import Cognizant from '../assets/images/Cognizant.png';
import wipro from '../assets/images/wipro.png';
import xoriant from '../assets/images/xoriant.jpg';
import EXFO from '../assets/images/exfo.png';

const Container = styled.div`
  padding: 20px;
`;

const Heading = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
`;

const Paragraph = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 10px;
  text-align: center;
`;

const TwoColumnLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
`;

const Column = styled.div`
  flex: 1;
  padding: 10px;
  &:first-child {
    border-right: 2px solid #333;
  }
`;

const ExperienceSection = styled.div`
  margin-bottom: 20px;
`;

const ExperienceHeading = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 10px;
  margin-left: 30px;
`;

const ExperienceItem = styled.div`
  margin-bottom: 10px;
`;

const EducationSection = styled.div`
  margin-top: 40px;
`;

const EducationHeading = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

const EducationItem = styled.div`
  margin-bottom: 10px;
`;

const ImageContainer = styled.div`
  flex: 1;
  text-align: center;
`;

const Image = styled.img`
  width: 150px;
  border-radius: 20%;
  @media (max-width: 768px) {
    width: 100px;
  }
`;

const ImageDeloitte = styled.img`
  width: 450px;
  border-radius: 30%;
  @media (max-width: 768px) {
    width: 300px;
  }
`;

const ImageCognizant = styled.img`
  width: 450px;
  border-radius: 20%;
  @media (max-width: 768px) {
    width: 300px;
  }
`;

const ImageWipro = styled.img`
  width: 200px;
  border-radius: 20%;
  @media (max-width: 768px) {
    width: 200px;
  }
`;

const ImageExfo = styled.img`
  width: 250px;
  border-radius: 5%;
  @media (max-width: 768px) {
    width: 200px;
  }
`;

const AboutMe = () => (
  <Container>
    <Heading>About Me</Heading>
    <Paragraph>
      My career journey has taken me through a variety of roles, each contributing to my deep expertise
      in blockchain, big data, and cloud technologies.
    </Paragraph>
    <Paragraph>
      Throughout my career, I have consistently delivered high-quality solutions and demonstrated a commitment to
      innovation and excellence. Whether you are looking to develop a new blockchain solution, optimize existing
      systems, or gain insights into the latest tech trends, I bring a wealth of knowledge and a proven track record
      of success. Let's connect and explore how we can collaborate to achieve your goals.
    </Paragraph>
    <ExperienceHeading>Professional Experience</ExperienceHeading>
    <TwoColumnLayout>
      <Column>
        <ExperienceSection>
          <ExperienceItem>
            <strong>Blockchain Consultant, Freelancer (03/2021 – Present)</strong>
            <p><u>Project Design and Development:</u> Designed, developed, and delivered multiple blockchain projects for global clients.</p>
            <p><u>Consultancy Services:</u> Provided strategic consultancy on blockchain projects to help clients achieve their goals.</p>
            <p><u>Educational Content:</u> Launched a YouTube channel to educate and inform about various blockchain technologies.</p>
            <p><u>Freelance Services:</u> Offered freelance blockchain development services on platforms like Upwork, Fiverr.</p>
            <p><a href="https://www.fiverr.com/himmatp" target="_blank" rel="noopener noreferrer">Fiverr Profile</a></p>
            <p><a href="https://www.upwork.com/freelancers/~01869d7634ec458a19" target="_blank" rel="noopener noreferrer">Upwork Profile</a></p>
          </ExperienceItem>
        </ExperienceSection>
      </Column>
      <Column>
        <ImageContainer>
            <Image src={upwork} alt="upwork" />
        </ImageContainer>
        <ImageContainer>
            <Image src={fiverr} alt="fiverr" />
        </ImageContainer>
      </Column>
    </TwoColumnLayout>
    <TwoColumnLayout>
      <Column>
        <ImageContainer>
            <ImageDeloitte src={deloitte} alt="deloitte" />
        </ImageContainer>
      </Column>
      <Column>
        <ExperienceSection>
          <ExperienceItem>
            <strong>Sr. Consultant, Deloitte (04/2019 – 02/2021)</strong>
            <ul>
                <li><p>Role in Architectural Team:</p></li>
                <ul>
                    <li><p><u>Cross-Discipline Expertise:</u> Actively participated as a member of the architectural team, focusing on integrating blockchain technology with cloud solutions and big data frameworks to develop cutting-edge digital infrastructure.</p></li>
                    <li><p><u>Innovative Strategy Development:</u> Played a key role in conceptualizing and strategizing the deployment of scalable and secure systems that leverage the strengths of blockchain, cloud computing, and big data analytics.</p></li>
                </ul>
            </ul>
            <ul>
                <li><p>Blockchain & Big Data Initiatives:</p></li>
                <ul>
                    <li><p><u>RFPs and POCs Management:</u> Led the preparation and response to multiple Requests for Proposals (RFPs) and developed Proofs of Concept (POCs) for a diverse clientele. This included tailoring blockchain solutions to meet specific business needs, from improving supply chain transparency to enhancing data security and operational efficiency.</p></li>
                    <li><p><u>Client-Specific Solutions:</u> Designed and implemented tailored blockchain and big data applications, such as decentralized identity systems for secure customer data management and distributed ledgers for financial transactions.</p></li>
                </ul>
            </ul>
            <ul>
                <li><p>Technical Leadership and Development:</p></li>
                <ul>
                    <li><p><u>Project Leadership:</u> Served as the technical lead in numerous projects, overseeing teams to ensure timely delivery of technological solutions while also contributing individually by writing robust, scalable code.</p></li>
                </ul>
            </ul>
            <ul>
                <li><p>Blockchain Implementations:</p></li>
                <ul>
                    <li><p><u>Smart Contracts:</u> Developed smart contracts to automate complex business agreements, such as real-time insurance claim processing and automated compliance checks.</p></li>
                    <li><p><u>Decentralized Applications (DApps):</u> Built several decentralized applications (DApps) on platforms like Ethereum and Binance Smart Chain, which provided solutions ranging from tokenized assets trading to decentralized finance (DeFi) platforms.</p></li>
                    <li><p><u>Data Integrity Solutions:</u> Designed systems to ensure the integrity and security of data across distributed networks, particularly for industries like healthcare and banking where data sensitivity is critical.</p></li>
                </ul>
            </ul>
          </ExperienceItem>
        </ExperienceSection>
      </Column>
    </TwoColumnLayout>
    <TwoColumnLayout>
      <Column>
        <ExperienceSection>
          <ExperienceItem>
            <strong>Sr. Associate, Cognizant (10/2017 – 04/2019)</strong>
            <p><u>Stock Market Repository Project:</u> Worked on the design, ingestion, ETL, reporting, optimization, and automation for the Indian stock market repository project.</p>
            <p><u>Performance Optimization:</u> Implemented optimization techniques for MapReduce, Hive, and Spark.</p>
            <p><u>UNIX Scripting Frameworks:</u> Developed various UNIX shell scripting frameworks tailored to different business use cases.</p>
            <p><u>RFPs:</u> Contributed to multiple requests for proposals (RFPs) for different clients.</p>
          </ExperienceItem>
        </ExperienceSection>
      </Column>
      <Column>
        <ImageContainer>
            <ImageCognizant src={Cognizant} alt="Cognizant" />
        </ImageContainer>
      </Column>
    </TwoColumnLayout>
    <TwoColumnLayout>
      <Column>
        <ImageContainer>
            <ImageWipro src={wipro} alt="wipro" />
        </ImageContainer>
      </Column>
      <Column>
        <ExperienceSection>
          <ExperienceItem>
            <strong>Sr. Project Engineer, Wipro (10/2015 – 09/2017)</strong>
            <p><u>Data Platform Architecture:</u> Spearheaded the development of a Big Data platform utilizing Hadoop (CDH5) and associated technologies like MapReduce, Hive, and Oozie.</p>
            <p><u>Data Ingestion:</u> Engineered solutions for importing data in various formats (Delimited, EBCDIC, XML, etc.) into the Enterprise Data Provisioning Platform.</p>
            <p><u>Customization and Development:</u> Created custom Input Format classes tailored to diverse source systems.</p>
            <p><u>Hive Enhancements:</u> Modified Hive SerDes and developed custom Hive UDFs and UDTFs as per project requirements.</p>
            <p><u>ETL Process Design and Implementation:</u> Collaborated with the Client Data Model team to understand ETL designs, developing Hive scripts for data extraction and transformation for Dodd-Frank reporting.</p>
            <p><u>Testing and Automation:</u> Developed automated testing tools using Pig scripts and orchestrated ETL job automation using Oozie.</p>
            <p><u>Data Movement:</u> Facilitated data transfers between HDFS and Oracle databases using Sqoop.</p>
            <p><u>Oozie Customization:</u> Authored custom EL functions for enhanced Oozie job management.</p>
          </ExperienceItem>
        </ExperienceSection>
      </Column>
    </TwoColumnLayout>
    <TwoColumnLayout>
      <Column>
        <ExperienceSection>
          <ExperienceItem>
            <strong>Software Engineer, Xoriant (03/2015 – 09/2015)</strong>
            <p><u>Data Integration:</u> Managed complex MapReduce jobs for data handling.</p>
            <p><u>Real-Time Data Access:</u> Implemented solutions using Spark Streaming for immediate data availability.</p>
            <p><u>Data Loading:</u> Streamlined Hive data uploads using various compression methods to optimize performance.</p>
            <p><u>Job Automation:</u> Automated Sqoop jobs using Java API for seamless data operations.</p>
            <p><u>Technology Utilization:</u> Leveraged the Hadoop ecosystem, including tools like HDFS, MapReduce, Hive, and Spark, to deliver cutting-edge data solutions.</p>
          </ExperienceItem>
        </ExperienceSection>
      </Column>
      <Column>
        <ImageContainer>
            <ImageExfo src={xoriant} alt="xoriant" />
        </ImageContainer>
      </Column>
    </TwoColumnLayout>
    <TwoColumnLayout>
      <Column>
        <ImageContainer>
            <ImageExfo src={EXFO} alt="EXFO" />
        </ImageContainer>
      </Column>
      <Column>
        <ExperienceSection>
          <ExperienceItem>
            <strong>Software Developer, EXFO (11/2011 – 03/2015)</strong>
            <p><u>Network Test and Monitoring:</u> Developed solutions for network test and monitor devices, focusing on server communication and performing tasks such as authentication, software downloads, and installations using REST APIs.</p>
            <p><u>Technology Stack:</u> Utilized technologies like PHP, Oracle, Ext-JS, and AWS (EC2, Auto Scaling, Load Balancing) to deliver robust and scalable solutions.</p>
          </ExperienceItem>
        </ExperienceSection>
      </Column>
    </TwoColumnLayout>
    <EducationSection>
      <EducationHeading>Education</EducationHeading>
      <EducationItem>
        <p><strong>Degree:</strong> Bachelor of Engineering (Information Technology)</p>
        <p><strong>Institution:</strong> TKIET Warananagar</p>
        <p><strong>Graduation:</strong> 2010</p>
      </EducationItem>
    </EducationSection>
  </Container>
);

export default AboutMe;
