import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
`;

const Heading = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
`;

const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 20px;
`;

const ProjectCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  transition: transform 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
`;

const Thumbnail = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
`;

const ProjectTitle = styled.h2`
  font-size: 1.5rem;
  margin-top: 10px;
`;

const ProjectDescription = styled.p`
  font-size: 1rem;
  margin-top: 10px;
`;

const ProjectDetails = styled.p`
  font-size: 0.9rem;
  color: #555;
`;

const projects = [
  {
    title: 'Crypto Wallet (Ethereum)',
    description: 'Developed a secure crypto wallet for the Ethereum blockchain where you can store ETH, NFTs, and all your ERC-20 tokens.',
    details: 'Technologies: Ethereum, Solidity, Web3.js, React, Node.js',
    thumbnail: 'https://via.placeholder.com/500x300.png?text=Project+1',
    url: '',
  },
  {
    title: 'Decentralized Exchange Aggregator',
    description: 'Created a decentralized exchange aggregator to swap token at best available price.',
    details: 'Technologies: Ethereum, Solidity, Web3.js, React, Node.js',
    thumbnail: 'https://via.placeholder.com/500x300.png?text=Project+2',
    url: '',
  },
  {
    title: '...',
    description: '...',
    details: '...',
    thumbnail: 'https://via.placeholder.com/500x300.png?text=Project+3',
    url: 'https://example.com/project3',
  },
  {
    title: '...',
    description: '...',
    details: '...',
    thumbnail: 'https://via.placeholder.com/500x300.png?text=Project+4',
    url: 'https://example.com/project4',
  },
];

const Portfolio = () => (
  <Container>
    <Heading>My Portfolio</Heading>
    <ProjectGrid>
      {projects.map((project, index) => (
        <ProjectCard key={index} onClick={() => window.open(project.url, '_blank')}>
          <Thumbnail src={project.thumbnail} alt={project.title} />
          <ProjectTitle>{project.title}</ProjectTitle>
          <ProjectDescription>{project.description}</ProjectDescription>
          <ProjectDetails>{project.details}</ProjectDetails>
        </ProjectCard>
      ))}
    </ProjectGrid>
  </Container>
);

export default Portfolio;
