// Footer.js
import React from 'react';
import styled from 'styled-components';
import { FaLinkedin, FaGithub, FaYoutube } from 'react-icons/fa';

const FooterContainer = styled.footer`
  background-color: #333;
  color: #fff;
  padding: 25px;
  text-align: center;
`;

const SocialIcons = styled.div`
  font-size: 24px;
  margin-bottom: 5px;
`;

const IconLink = styled.a`
  color: #fff;
  text-decoration: none;
  margin: 0 15px;
  &:hover {
    color: #aaa;
  }
`;

const Footer = () => (
  <FooterContainer>
    <SocialIcons>
      <IconLink href="https://www.linkedin.com/in/himmat-patil/" target="_blank" rel="noopener noreferrer">
        <FaLinkedin />
      </IconLink>
      <IconLink href="https://github.com/iamhimmat89" target="_blank" rel="noopener noreferrer">
        <FaGithub />
      </IconLink>
      <IconLink href="https://www.youtube.com/@Himmat_Patil" target="_blank" rel="noopener noreferrer">
        <FaYoutube />
      </IconLink>
    </SocialIcons>
    <p>&copy; 2024 Himmat Patil. All rights reserved.</p>
  </FooterContainer>
);

export default Footer;
