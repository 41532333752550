// GlobalStyle.js or similar global styles file
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    body {
        font-family: Arial, sans-serif;
        background-color: #f8f9fa;
    }

  #root {
    padding-top: 80px; /* Adjust this value based on the height of your header */
  }
`;

export default GlobalStyle;
